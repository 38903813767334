



































import { defineComponent, computed, onMounted } from "@vue/composition-api";

export default defineComponent({
	setup(props, context) {
		const lang = computed(() => context.root.$store.state.appCulture);

		const widgetOptions = {
			language: "it",
			chkPrivacyVisible: true,
			chkMarketingVisible: false,
			privacyPolicyLink: "https://www.k-digitale.com",
			marketingPolicyLink: ""
		};

		onMounted(() => {
			try {
				(window as any).initWEOnDemand("#kd-widget", widgetOptions);
			} catch (error) {
				console.log("Errore inizializzazione widget Expert On Demand: " + error);
			}
		});

		return {};
	}
});
